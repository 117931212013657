export class ValueTools {
	static isEqualTo(value: any, values: Array<any>) {
		for (const v of values) {
			if (value === v) {
				return true
			}
		}
		return false
	}

	static isNull(value: any) {
		return value === null
	}

	static isNullOrUndefined(value: any) {
		return ValueTools.isNull(value) || ValueTools.isUndefined(value)
	}

	static isUndefined(value: any) {
		return value === undefined
	}
}