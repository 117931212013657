// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import CompanyLogo from '@/Components/Extras/CompanyLogo/template.vue'

// Constants
import { Component } from '@/Constants/Global/Component'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'

// Component Extend
const PopupComment = VueMixins(MixinBase, MixinComponent).extend({
	name: 'PopupComment',

	components: {
		CompanyLogo
	},

	props: {
		show: Boolean,
	},

	data: function() {
		return {
			states: {
				action: Component.Actions.INSERT,
				buttonText: 'Aceptar',
				comment: '',
				placeholder: 'Agregar un comentario...',
				title: 'PlaceHolder'
			}
		}
	},

	methods: {
		_getButtonText: function() {
			return this.states.buttonText
		},

		_getPlaceHolder: function() {
			return this.states.placeholder
		},

		_getTitle: function() {
			return this.states.title
		},

		onPCButtonClick: function() {
			DevelopmentTools.printWarn('[PopupComment]:onPCButtonClick event triggered')
			this.$emit('onPCButtonClick', this.states.action, this.states.comment)
		},

		onPCClose: function() {
			DevelopmentTools.printWarn('[PopupComment]:onPCClose event triggered')
			this.$emit('onPCClose')
		}
	}
})

// Exports
export default PopupComment
export type PopupCommentRef = InstanceType<typeof PopupComment>