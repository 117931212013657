// ./
import { parseDataResponse, parseHistoryResponse, parseStoppedActivesResponse } from './response.parser'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components
import BasicHeader             from '@/Components/Global/BasicHeader/template.vue'
import DataTable               from '@/Components/Global/DataTable/template.vue'
import { DataTableRef }        from '@/Components/Global/DataTable/component'
import InitialsButton          from '@/Components/Global/InitialsButton/template.vue'
import PopupTable              from '@/Components/Global/PopupTable/template.vue'
import { PopupTableRef }       from '@/Components/Global/PopupTable/component'
import FilterBar               from '@/Components/Modules/3/FilterBar/template.vue'
import PopupComment            from '@/Components/Modules/3/PopupComment/template.vue'
import { PopupCommentRef }     from '@/Components/Modules/3/PopupComment/component'
import PopupServiceForm        from '@/Components/Modules/3/PopupServiceForm/template.vue'
import { PopupServiceFormRef } from '@/Components/Modules/3/PopupServiceForm/component'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'
import { Documents } from '@/Constants/Global/Documents'
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'
import { Module3 }   from '@/Constants/Modules/Module3'

// Dependencies
import { Socket } from 'socket.io-client'
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View30 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Modules.View30.NAME,

	components: {
		BasicHeader,
		DataTable,
		FilterBar,
		InitialsButton,
		PopupComment,
		PopupTable,
		PopupServiceForm
	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.INSERT,
				documentToUpdate: undefined,
				isStoppedActivesEnabled: false,
				serviceState: Module3.M30.Defaults.States.NONE,
				showDataTable: true,
				showPopupComment: false,
				showPopupServiceForm: false,
				showPopupTable: false
			}
		}
	},

	created: function() {
		// this.doFetchValidationFor(Server.Fetching.Validations.COMPANIES)
		// this.doFetchValidationFor(Server.Fetching.Validations.EQUIPMENTS)
		// this.doFetchValidationFor(Server.Fetching.Validations.PERMISSIONS)
		// this.doFetchValidationFor(Server.Fetching.Validations.ROLES)
		// this.doFetchValidationFor(Server.Fetching.Validations.SERVICES)
		// this.doFetchValidationFor(Server.Fetching.Validations.STORAGES)
		// this.doFetchValidationFor(Server.Fetching.Validations.USERS)
		// this.doFetchValidationFor(Server.Fetching.Validations.WORKAREAS)
	},

	mounted: function() {
		this._checkSystemAccess()
		this._initDataTable()
		this._initPermissions()
		this._initSocketEvents()

		// Habilitar la propiedad 'selectable' del componente 'DataTable'.
		const popupDataTable = this._popupServiceForm._popupTable._dataTable
		this._dataTable.setSelectable(false)
		popupDataTable.setSelectable(true)

		// Establecer Altura Maxima de los componentes 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({ maxHeight: 'calc(100vh - 228px);' })
		popupDataTable.setStates<DataTableRef['states']>({ maxHeight: 675 })

		// Ocultar los Controles del Popup que muestra el Historial.
		this._popupTable._dataTable.setStates<DataTableRef['states']>({ showSearchBar: false })
	},

	beforeDestroy: function() {
		// Conexión del Cliente del SocketIO.
		const socket: Socket = Store.getters.getStoredSocket

		// Asegurar que los eventos dejen de estar activos.
		socket.off(Module3.M30.Socket.Events.CREATED)
		socket.off(Module3.M30.Socket.Events.UPDATED)
	},

	computed: {
		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_filteredInternalUsers: function(): Array<object> {
			return Store.getters.getStoredUsers.filter((f: any): (object | void) => {
				if (f._idRole !== Documents.Roles.Kreis.ROOT && f._idRole !== Documents.Roles.Kreis.CLIENT) {
					return f
				}
			})
		},

		_filteredWorkAreas: function(): Array<object> {
			return Store.getters.getStoredWorkAreas.filter((f: { name: string }): (object | void) => {
				if (f.name !== 'Root' && f.name !== 'Clientes') {
					return f
				}
			})
		},

		_popupComment: function(): PopupCommentRef {
			return this.$refs.popupComment as PopupCommentRef
		},

		_popupServiceForm: function(): PopupServiceFormRef {
			return this.$refs.popupServiceForm as PopupServiceFormRef
		},

		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		_checkSystemAccess: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			if ('ACCESS_MODULE_30' in userPermissions) {
				const permission = userPermissions.ACCESS_MODULE_30?.privileges.read
				if (!permission) this.$router.push({ name: VueRouter.Modules.View10.NAME, params: { unauthorizedRedirect: 'true' } })
			}
		},

		_closePopupServiceForm: function() {
			this.setStates<View30Ref['states']>({ serviceState: Module3.M30.Defaults.States.NONE, showPopupServiceForm: false })
			this._popupComment.setStates<PopupCommentRef['states']>({ comment: '' })
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ traceability: 0 })
			this._popupServiceForm.resetInputs()
		},

		_completePopupServiceForm: function (includeTraceability?: boolean) {
			// Destructuración de los datos correspondiente al Servicio seleccionado.
			const { documentToUpdate } = this.states
			const { _idContact, _idEquipment, _idStorage, _idTechnical, _idWorkArea } = documentToUpdate
			const { workarea, company, details, internal, observation, sheet, storage, title } = documentToUpdate
			const { equipment, statusEquipment, technical } = documentToUpdate
			const { dateProgram, traceability, type } = documentToUpdate

			// Separar la Fecha y Hora de la propiedad 'dateProgram'
			const sDate = dateProgram?.split(' ') || ''
			const date = PrimitiveTools.Strings.stringify(sDate[0], '')
			const time = PrimitiveTools.Strings.stringify(sDate[1], '')

			// Obtener la información del Contacto.
			let email = null, phone = null, contactName = null
			const contact = Store.getters.getStoredUserById(_idContact)
			if (contact) { contactName = `${contact.name} ${contact.pLastName}`, email = contact.email, phone = contact.phone[0] }

			// Almacenar los datos de la Solicitud a modificar.
			if (includeTraceability) this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ traceability })

			// Completar los campos no modificables.
			this._popupServiceForm.setAllInputs({
				active: { _id: _idEquipment, value: _idEquipment ? equipment : AppValues.Strings.DEFAULT_EMPTY_STRING },
				contact: { _id: _idContact, email: email, phone: phone, value: contactName },
				dateProgram: date,
				timeProgram: time,
				observation: PrimitiveTools.Strings.stringify(observation, ''),
				statusEquipment: !statusEquipment,
				storage: { _id: _idStorage, value: storage },
				technical: { _id: _idTechnical, value: technical },
				type: { value: type },
				workarea: { _id: _idWorkArea, value: workarea },
				sheet: parseInt(sheet) || '',
				company, details, internal, title
			})
		},

		_createServiceRequest: async function() {			
			// Datos requeridos para ingresar un nuevo Servicio.
			const { inputs }      = this._popupServiceForm
			const _idResponsible  = Store.getters.getStoredUser?._idUser
			const _idStorage      = inputs.storage._id
			const _idEquipment    = inputs.active._id
			const _idWorkArea     = inputs.workarea._id
			const _idContact      = inputs.contact._id
			const _idTechnical    = inputs.technical._id
			const dateProgram     = inputs.dateProgram
			const hourProgram     = inputs.timeProgram
			const internal        = inputs.internal
			const statusEquipment = !inputs.statusEquipment
			const sheet           = inputs.sheet ? parseInt(inputs.sheet) : 0
			const type            = inputs.type.value
			const title           = inputs.title?.trim()
			const details         = inputs.details?.trim()
			const observation     = inputs.observation?.trim()

			// Prevalidación para la Fecha y Hora.
			if (dateProgram && !hourProgram) {
				this.showToast('Hora no especificada', 'La Hora debe ser especificada si se seleccionó una Fecha.', 'warning')
				return
			}
			else if (!dateProgram && hourProgram) {
				this.showToast('Fecha no especificada', 'La Fecha debe ser especificada si se seleccionó una Hora.', 'warning')
				return
			}

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idResponsible, _idStorage, _idEquipment, _idWorkArea, _idContact, _idTechnical,
				sheet, type, statusEquipment, dateProgram, hourProgram, title, details, observation, internal
			}

			// Validación de los campos de la petición.
			const result = Module3.M30.JoiSchemas.AddService.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al crear un Servicio', result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.POST, path: Server.Routes.Services.AddService, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Agregar la respuesta del nuevo Servicio al Store.
				const service = response.data.body
				Store.commit('addService', service[0])

				// Agregar el nuevo registro a la tabla.
				const { fields, items, actions } = parseDataResponse(service)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)
				this._dataTable.addRow(items[0])

				// Cerrar el formulario PopUp y mostrar el Toast al Usuario.
				this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ isFetching: false })
				this._closePopupServiceForm()
				this._initStoppedActivesTable()
				this.showToast('Solicitud Creada', 'La Solicitud a sido creada correctamente!', 'success')
			}
		},

		_getDataTableFromPopupServiceForm: function() {
			return this._popupServiceForm._popupTable._dataTable
		},

		_getServicesStoppedActives: function() {
			// Obtener el listado de los Activos Detenidos.
			const { _dataTable } = this._popupTable
			const services = Store.getters.getStoredServices
			if (services.length > 0) {
				// Extraer la información requerida para mostrar en el componente.
				const actives = []

				for (const $s of services) {
					// Aplicar filtrado de los Equipos.
					if ($s.statusEquipment === false) {
						// Calcular el # de días que lleva detenido.
						const registrationDate = PrimitiveTools.Dates.parseDateString($s.registrationDate)
						const daysCount = registrationDate ? Math.abs(PrimitiveTools.Dates.getNumDaysBetween(registrationDate.date)) : AppValues.Strings.DEFAULT_EMPTY_STRING

						// Agregar los Objetos que posteriormente serán mostrados en la tabla.
						actives.push({ ...$s, daysCount })
					}
				}

				if (actives.length > 0) {
					const { fields, items, actions } = parseStoppedActivesResponse(actives)
					_dataTable.setElements(fields, items, actions)
					_dataTable.sortOrder('dateStop', 'desc')
				}
			}
		},

		_initDataTable: function() {
			const services = Store.getters.getStoredServices
			if (services?.length > 0) {
				const { fields, items, actions } = parseDataResponse(services)
				this._dataTable.setElements(fields, items, actions)
				this._dataTable.sortOrder('registrationDate', 'desc')
				this._dataTable.resetEmptyText()
			}
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_30_MANAGE_SERVICES?.privileges.write)
			this._dataTable.setPermission('ACTION_HISTORY', userPermissions.MODULE_30_CHECK_HISTORY?.privileges.read)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_30_MANAGE_SERVICES?.privileges.write)
			this._dataTable.setPermission('STATUS_BUTTON', true)
			this._dataTable.setPermission('STATUS_ICON', true)
			this._popupTable._dataTable.setPermission('COMMENT_ICON', true)
		},

		_initServiceHistory: function(service: any) {
			// Referencia de los Componentes.
			const { _dataTable } = this._popupTable
			_dataTable.setStates<DataTableRef['states']>({ showControls: true })

			// Actualizar el titulo al componente <PopupTable>
			this._popupTable.setTitle('Historial de Actividades')
			this._popupTable.setSelectVisible(false)

			// Información correspondiente al Historial del Servicio.
			const { company, equipment, sheet, storage, title, type, workarea } = service
			const { fields, items, actions } = parseHistoryResponse(service.history)
			this._popupTable.setStates<PopupTableRef['states']>({ showCustomHeader: true })

			// Asignar el Header personalizado del componente <PopupTable>
			this._popupTable.setCustomHeader(
				[
					{ title: 'Asunto', value: title },
					{ title: 'Cliente', value: company },
					{ title: 'Ubicación', value: storage },
					{ title: 'Activo', value: equipment }
				],
				[
					{ title: '' },
					{ title: 'Num', value: sheet },
					{ title: 'Tipo', value: type },
					{ title: 'Área', value: workarea }
				]
			)

			// Establecer los datos obtenidos en la respuesta.
			_dataTable.setFields(fields)
			_dataTable.setItems(items)
			_dataTable.setActions(actions)
			this.setStates<View30Ref['states']>({ showPopupTable: true })
		},

		_initSocketEvents: function() {
			// Conexión del Cliente del SocketIO.
			const socket: Socket = Store.getters.getStoredSocket

			// Evento Socket cuando un Servicio es Creado.
			socket.on(Module3.M30.Socket.Events.CREATED, (payload: any) => {
				// Agregar la respuesta del nuevo Servicio al Store.
				Store.commit('addService', payload)

				// Agregar el nuevo registro a la tabla.
				const { fields, items, actions } = parseDataResponse([payload])
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)
				this._dataTable.addRow(items[0])

				// Obtener información sobre el Servicio creado.
				const storage = Store.getters.getStoredStorageById(payload._idStorage)
				const user = Store.getters.getStoredUserById(payload._idResponsible)

				// Notificar al Usuario.
				const forStorage = storage ? ` asociado a Ubicación ${ storage.code }.` : '.'
				const message = `${ user.name } ${ user.pLastName } ha creado un Servicio con Asunto '${ payload.title }'${ forStorage }`
				this.showToast('Servicio Creado', message, 'success')
			})

			// Evento Socket cuando un Servicio es Actualizado.
			socket.on(Module3.M30.Socket.Events.UPDATED, (payload: any) => {
				// Actualizar la respuesta del Servicio al Store.
				Store.commit('updateService', payload)

				// // Actualizar el registro en la tabla.
				const { fields, items, actions } = parseDataResponse([payload])
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)
				this._dataTable.updateRow(items[0], '_idService')

				// Obtener información sobre el Servicio actualizado.
				const storage = Store.getters.getStoredStorageById(payload._idStorage)
				const { _idResponsible } = payload.history[payload.history.length - 1]
				const user = Store.getters.getStoredUserById(_idResponsible)

				// Notificar al Usuario.
				const forStorage = storage ? ` asociado a Ubicación ${ storage.code }.` : '.'
				const message = `${ user.name } ${ user.pLastName } ha actualizado el Servicio con Asunto '${ payload.title }'${ forStorage }`
				this.showToast('Servicio Actualizado', message, 'success')
			})
		},

		_initStoppedActivesTable: function() {
			// Actualizar el titulo al componente <PopupTable>
			this._popupTable.setTitle('Activos Detenidos')
			this._popupTable.setSelectVisible(false)
			this._popupTable.setStates<PopupTableRef['states']>({ showCustomHeader: false })

			// Mostrar los Controles en el componente 'PopupTable/DataTable'.
			const { _dataTable } = this._popupTable
			_dataTable.setStates<DataTableRef['states']>({ showControls: true, showRefreshButton: false })

			// Ejecutar la función que deberá obtener el listado de Activos
			// que se encuentran detenidos en los Servicios Creados a Inicio Ruta.
			this._getServicesStoppedActives()
		},

		_resolvePopupTableTitle: function (key: string) {
			// Segun la Key del botón presionado, actualizar el titulo.
			let name = ''
			switch (key) {
				case 'active': name = 'Equipo o Activo'; break
				case 'contact': name = 'Contacto'; break
				case 'storage': name = 'Ubicación'; break
				case 'technical': name = 'Técnico'; break
				case 'type': name = 'Tipo Solicitud'; break
				case 'workarea': name = 'Área de Trabajo'; break
			}
			this._popupServiceForm._popupTable.setTitle(`Seleccionar ${ name }`)
		},

		_updateServiceRequest: async function (comment?: string) {
			const { documentToUpdate, serviceState } = this.states
			const { inputs } = this._popupServiceForm

			// Datos requeridos para actualizar el Servicio.
			const _idResponsible   = Store.getters.getStoredUser._idUser
			const { _idService }   = documentToUpdate
			const _idStorage       = inputs.storage._id
			const _idContact       = inputs.contact._id
			const _idEquipment     = inputs.active._id
			const _idTechnical     = inputs.technical._id
			const _idWorkArea      = inputs.workarea._id
			const dateProgram      = inputs.dateProgram
			const hourProgram      = inputs.timeProgram
			const internal         = inputs.internal
			const statusEquipment  = !inputs.statusEquipment
			const title            = inputs.title?.trim()
			const details          = inputs.details?.trim()
			const observation      = inputs.observation?.trim()
			const sheet            = inputs.sheet ? parseInt(inputs.sheet) : 0
			const type             = inputs.type.value
			const stage            = serviceState
			const { traceability } = documentToUpdate

			// Prevalidación para la Fecha y Hora.
			if (dateProgram === '' && hourProgram !== '') {
				this.showToast('Fecha no especificada', 'La Fecha debe ser especificada si se seleccionó una Hora.', 'warning')
				return
			}
			else if (hourProgram === '' && dateProgram !== '') {
				this.showToast('Hora no especificada', 'La Hora debe ser especificada si se seleccionó una Fecha.', 'warning')
				return
			}

			// Si se esta cambiado de estado, la Fecha Programada es obligatoria.
			if (serviceState !== Module3.M30.Defaults.States.NONE && serviceState !== Module3.M30.Defaults.States.REJECTED && serviceState !== Module3.M30.Defaults.States.ANNULED) {
				if (!dateProgram || !hourProgram) {
					this.showToast('Fecha y Hora Programada', 'Al cambiar de estado, la Fecha y Hora Programada deben ser especificados!', 'warning')
					return
				}
			}

			// Objeto con las Propiedades requeridas por la Petición.
			const body: any = {
				_idService, _idStorage, _idResponsible, _idTechnical, _idContact, _idWorkArea, _idEquipment,
				sheet, dateProgram, hourProgram, title, details, observation, internal, statusEquipment, type, comment, stage
			}

			// Validación de los campos de la petición.
			const result = Module3.M30.JoiSchemas.UpdateService.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, 'Error al actualizar el Servicio', result.error)

			// Validar el estado del Servicio, si el 'Status' es Rechazado, debe alertar al Usuario que el estado cambiará a 'Aceptado'.
			if (traceability === Module3.M30.Defaults.Traceability.REJECTED) body.stage = Module3.M30.Defaults.States.CREATED

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ isFetching: true })
			this.setStates<View30Ref['states']>({ showPopupComment: false })

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.PATCH, path: Server.Routes.Services.UpdateService, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Actualizar la respuesta del Servicio al Store.
				const service = response.data.body
				Store.commit('updateService', service[0])

				// Actualizar el registro en la tabla.
				const { fields, items, actions } = parseDataResponse(service)
				this._dataTable.setFields(fields)
				this._dataTable.setActions(actions)
				this._dataTable.updateRow(items[0], '_idService')

				// Cerrar el formulario PopUp y mostrar el Toast al Usuario.
				this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ isFetching: false })
				this._closePopupServiceForm()
				this._initStoppedActivesTable()
				this.showToast('Solicitud Actualizada', 'La Solicitud a sido actualizada correctamente!', 'success')
			}
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onDTButtonClick: function (key: string, row: any) {
			this.setStates<View30Ref['states']>({ documentToUpdate: row.item })
			if (key === 'edit') {
				this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ action: Component.Actions.UPDATE })
				this.setStates<View30Ref['states']>({ showPopupServiceForm: true })
				this._completePopupServiceForm(true)
			}
			else if (key === 'history') {
				this._initServiceHistory(row.item)
			}
		},

		onDTNewButtonClick: function() {
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ action: Component.Actions.INSERT })
			this.setStates<View30Ref['states']>({ showPopupServiceForm: true })

			// Rellenar los campos por defecto.
			const workareas = Store.getters.getStoredWorkAreas
			if (workareas?.length > 0) {
				workareas.forEach((x: any) => {
					if (x.name === 'Servicio Terreno') this._popupServiceForm.setInput('workarea', { _id: x._idWorkArea, value: x.name })
				})
			}
			this._popupServiceForm.setInput('type', { value: 'Correctivo' })
		},

		onDTRefreshButtonClick: async function() {
			await Store.dispatch('fetchServices')
			this._initDataTable()
		},

		onFBLabelClick: function (status: string) {
			// Obtener el valor actual del filtro si existe alguno.
			const { filterBy } = this._dataTable.states

			// Listado completo de Servicios.
			const services = Store.getters.getStoredServices
			const filtered = []

			// Realizar el proceso de filtrado solo si existen Servicios creados.
			if (services?.length > 0) {
				const { items } = parseDataResponse(services)

				// Filtrar las Solicitudes por el botón del componente 'FilterBar'.
				if (filterBy !== status) {
					for (const service of items) {
						if (service.status === status) {
							filtered.push(service)
						}
					}
					this._dataTable.setItems(filtered)
					this._dataTable.setStates<DataTableRef['states']>({ filterBy: status })
				}
				else {
					this._dataTable.setItems(items)
					this._dataTable.setStates<DataTableRef['states']>({ filterBy: undefined })
				}
			}
		},

		onFBLabelStoppedActivesClick: function() {
			this.setStates<View30Ref['states']>({ showPopupTable: true })
			this._initStoppedActivesTable()
		},

		onPCButtonClick: function (action: number, comment: string) {
			if (action === Component.Actions.READ) {
				this._popupComment.setStates<PopupCommentRef['states']>({ comment: '' })
			}
			else if (action === Component.Actions.UPDATE) {
				this._updateServiceRequest(comment)
			}
		},

		onPCClose: function() {
			this.setStates<View30Ref['states']>({ serviceState: Module3.M30.Defaults.States.NONE, showPopupComment: false })
			this._popupComment.setStates<PopupCommentRef['states']>({ comment: '' })
		},

		onPSFCloseClick: function() {
			this._closePopupServiceForm()
		},

		onPSFCopyServiceClick: function() {
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ action: Component.Actions.INSERT, traceability: 0 })
			this._completePopupServiceForm()
			this._popupServiceForm.inputs.sheet = ''
			this.showToast('Formulario Copiado', 'Se han rellenado los campos del formulario con los datos anteriores.', 'success')
		},

		onPSFServiceActionClick: function (action: string) {
			const isDeclining = action === 'decline'
			this._popupComment.setStates<PopupCommentRef['states']>({ action: Component.Actions.UPDATE, title: isDeclining ? 'Razón Rechazo' : 'Anulación de Servicio' })
			this.setStates<View30Ref['states']>({ serviceState: isDeclining ? Module3.M30.Defaults.States.REJECTED : Module3.M30.Defaults.States.ANNULED, showPopupComment: true })
		},

		onPSFInputButtonClick: async function (key: string) {
			// Referencia de los Componentes
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ showPopupTable: true })
			const _dataTable = this._getDataTableFromPopupServiceForm()
			_dataTable.resetFetchState()
			this._resolvePopupTableTitle(key)

			if (key === 'storage') {
				const storages = Store.getters.getStoredStorages
				if (storages?.length > 0) {
					// const { fields, items, actions } = DataParsers.Storages.SummaryParser(storages)
					// _dataTable.setElements(fields, items, actions)
					// _dataTable.sortOrder('code', 'asc')
				}
			}
			else if (key === 'active') {
				const equipments = Store.getters.getStoredEquipments
				if (equipments?.length > 0) {
					const storage: any = this._popupServiceForm.getInput('storage')
					const filtered = equipments.filter((x: any) => x._idStorage === storage._id)
					const actives = [...filtered]

					const { fields, items } = DataParsers.Equipments.SummaryParser(actives)
					_dataTable.setElements(fields, items)
					_dataTable.sortOrder('code', 'asc')
				}
			}
			else if (key === 'type') {
				const { fields, items } = await Store.dispatch('fetchServiceTypes')
				_dataTable.setElements(fields, items)
			}
			else if (key === 'workarea') {
				if (this._filteredWorkAreas) {
					const { fields, items } = DataParsers.WorkAreas.SummaryParser(this._filteredWorkAreas)
					_dataTable.setElements(fields, items)
				}
			}
			else if (key === 'contact') {
				const storage: any = this._popupServiceForm.getInput('storage')
				const users = Store.getters.getStoredUsers
				if (users?.length > 0) {
					const aux = []
					// Los Usuarios tienen un Listado de posibles Ubicaciones.
					for (const $c of users) {
						for (const $s of $c.storages) {
							if ($s === storage._id) {
								aux.push($c)
								break
							}
						}
					}
					// Es posible que algunas ubicaciones no tengan ningun usuario cliente asociado.
					if (aux.length > 0) {	
						const { fields, items } = DataParsers.Users.SummaryExternalsParser(aux)
						_dataTable.setElements(fields, items)
						_dataTable.sortOrder('code', 'asc')
					}
				}
			}
			else if (key === 'technical') {
				// if (this._filteredInternalUsers) {
				// 	const { fields, items } = DataParsers.Users.SummaryInternalsParser(this._filteredInternalUsers)
				// 	_dataTable.setElements(fields, items)
				// 	_dataTable.sortOrder('rut', 'asc')
				// }
			}
		},

		onPSFRefreshButtonClick: async function(key: string) {
			switch (key) {
				case 'storage':
					await Store.dispatch('fetchStorages')
					break
				case 'active':
					await Store.dispatch('fetchEquipments')
					break
				case 'workarea':
					await Store.dispatch('fetchWorkAreas')
					break
				case 'contact': case 'technical':
					await Store.dispatch('fetchUsers')
					break
			}
			this.onPSFInputButtonClick(key)
		},

		onPSFServiceStateClick: function (key: string) {
			this._popupComment.setStates<PopupCommentRef['states']>({ action: Component.Actions.UPDATE, title: 'Comentario Estados' })
			this.setStates<View30Ref['states']>({ serviceState: key, showPopupComment: true })
		},

		onPSFSubmitClick: function (action: number) {
			if (action === Component.Actions.INSERT) {
				this._createServiceRequest()
			}
			else if (action === Component.Actions.UPDATE) {
				this._updateServiceRequest()
			}
		},

		onPTButtonClick: function (key: string, row: any) {
			if (key === 'state') {
				this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ action: Component.Actions.UPDATE })
				const service = Store.getters.getStoredServices.find((x: any) => x.sheet === row.item.sheet)
				this.setStates<View30Ref['states']>({ documentToUpdate: service, showPopupServiceForm: true })
				this._completePopupServiceForm(true)
			}
			else if (key === 'view') {
				this._popupComment.setStates<PopupCommentRef['states']>({ action: Component.Actions.READ, title: 'Ver Estado', comment: row.item.comment })
				this.setStates<View30Ref['states']>({ showPopupComment: true })
			}
		},

		onPTClose: function() {
			this.setStates<View30Ref['states']>({ showPopupTable: false })
			this._dataTable.clearCurrentRow()
		},

		onPTRefreshButtonClick: function() {
			// TODO: Obtener una versión actualizada del Servicio.
			this._initServiceHistory(this.states.documentToUpdate)
		},

		onPTSelect: function(key: string, currentRow: any) {
			let inputValue = null
			switch (key) {
				case 'storage':
					inputValue = { _id: currentRow.item._idStorage,
						value: `${ currentRow.item.code } ${ currentRow.item.name }`
					}
					this._popupServiceForm.setInput('active', this._popupServiceForm.getEmptyIdValueObject())
					this._popupServiceForm.setInput('company', currentRow.item.company)
					break
				case 'active':
					inputValue = { _id: currentRow.item._idEquipment,
						value: `${ currentRow.item.code } ${ currentRow.item.model }`
					}
					break
				case 'workarea':
					inputValue = { _id: currentRow.item._idWorkArea,
						value: currentRow.item.name
					}
					break
				case 'contact':
					inputValue = { _id: currentRow.item._idUser,
						email: currentRow.item.email,
						phone: currentRow.item.phone[0],
						value: currentRow.item.name
					}
					break
				case 'technical':
					inputValue = { _id: currentRow.item._idUser,
						value: currentRow.item.name
					}
					break
				case 'type':
					inputValue = { value: currentRow.item.name }
					break
			}
			this._popupServiceForm.setInput(key, inputValue)
		},

		onServerCaughtFetchException: function (path: string, resolution: Server.Fetching.Resolutions, status: number, error: any) {
			this._popupServiceForm.setStates<PopupServiceFormRef['states']>({ isFetching: false })
			if (status === Server.Response.StatusCodes.EXPECTATION_FAILED) {
				const longMessage = 'Ha ocurrido un error inesperado en el servidor al procesar la petición. Por favor, contacte a un Administrador.'
				this.showToast('Error de Servidor', longMessage, 'danger')
				return
			}
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Equipments, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, ['_checkSystemAccess', '_initPermissions']),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Users, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.WorkAreas, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Systems.Names.Module3_Services, '_initDataTable')
	}
})

// Exports
export default View30
export type View30Ref = InstanceType<typeof View30>