// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import CompanyLogo       from '@/Components/Extras/CompanyLogo/template.vue'
import SubmitButton      from '@/Components/Extras/SubmitButton/template.vue'
import PopupTable        from '@/Components/Global/PopupTable/template.vue'
import { PopupTableRef } from '@/Components/Global/PopupTable/component'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Module3 }   from '@/Constants/Modules/Module3'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'

// Component Extend
const PopupServiceForm = VueMixins(MixinBase, MixinComponent).extend({
	name: 'PopupServiceForm',

	components: {
		CompanyLogo,
		PopupTable,
		SubmitButton
	},

	props: {
		show: Boolean
	},

	data: function() {
		return {
			inputs: undefined,
			states: {
				action: Component.Actions.INSERT,
				inputButtonClicked: undefined,
				isFetching: false,
				showPopupTable: false,
				traceability: 0
			}
		}
	},

	created: function() {
		this.resetInputs()
	},

	computed: {
		_getServiceStatus: function() {
			const status = []
			for (const propName in Module3.M30.Defaults.States) {
				if (status.length < 6) status.push({
					key: propName,
					value: Module3.M30.Defaults.States[propName]
				})
			}
			return status
		},

		_getSubmitButtonUpdateText: function(): string {
			return this._isDeclined ? 'Reagendar' : 'Actualizar'
		},

		_isAnnulled: function(): boolean {
			return this.states.traceability === 8
		},

		_isConfirmed: function(): boolean {
			return this.states.traceability === 6
		},
		
		_isDeclined: function(): boolean {
			return this.states.traceability === 7
		},

		_isUpdating: function(): boolean {
			return this.states.action === Component.Actions.UPDATE
		},

		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		_checkDisabledForUserInputs: function(index: number) {
			if (this._isAnnulled || this._isConfirmed) return true
			return index === 1 && this.inputs.storage._id === undefined
		},
		
		_getButtonServiceState: function(index: number): (string | null) {
			for (const propName in Module3.M30.Defaults.StatesOrder) {
				const propValue = Module3.M30.Defaults.StatesOrder[propName]
				if (index === propValue) return Module3.M30.Defaults.States[propName]
			}
		},

		_getButtonStateIcon: function(index: number) {
			switch (index) {
				case 0: return 'file-upload'
				case 1: return 'handshake'
				case 2: return 'shipping-fast'
				case 3: return 'wrench'
				case 4: return 'clipboard-check'
				default: return 'check-circle'
			}
		},

		_getButtonStatesDisabled: function(index: number) {
			// Si la Trazabilidad es mayor a 6, deshabilitar todos los botones.
			const { traceability } = this.states
			if (traceability > 6) return 'BY_TRACEABILITY'

			// Si la Trazabilidad es igual a 6, deshabilitar todos los botones.
			if (traceability === 6) return 'BY_CONFIRMATION'

			// Si el Indice es 0, deshabilitar el Estado Creado.
			if (index === 0 || (index === traceability - 1)) return 'BY_INDEX'

			return null
		},

		_getButtonStatesStyle: function(index: number) {
			// Si la Trazabilidad es menor al Index, renderizarlo como 'deshabilitado'.
			const disabledStyle = `
				background-color: #DDD;
				color: #968F89;
			`
			// Si la Trazabilidad es 7 o mayor, renderizar todos los botones como deshabilitados.
			if (this._getButtonStatesDisabled(index) === 'BY_TRACEABILITY') {
				return disabledStyle
			}

			// Sino, validar hasta que botón, renderizarlo como deshabilitado.
			if ((this.states.traceability - 1) < index) {
				return disabledStyle
			}
			else {
				const color = this._indexToColorName(index).toLowerCase()
				return `
					background-color: var(--${color});
				`
			}
		},
		
		_getColoredStyle: function(addBackground = false, addBorder = false) {
			const { traceability } = this.states
			let color: string

			// Asignar un color al borde, segun la Trazabilidad del Servicio.
			if (traceability > 0 && traceability < 7) {
				color = `var(--${this._indexToColorName(traceability - 1)})`
			}
			else {
				switch (traceability) {
					case 0: color = '#EEE'; break
					case 7: color = 'var(--orange)'; break
					case 8: color = 'var(--gray)'; break
				}
			}

			return `
				${ addBackground ? `background-color: ${ color };` : '' }
				${ addBorder ? `border-color: ${ color };` : '' }
			`
		},

		_getCurrentButtonStateClass: function(index: number) {
			return this.states.traceability - 1 === index ? 'current' : null
		},

		_getFirstRowInputButtonKey: function(index: number) {
			switch (index) {
				case 0: return 'storage'
				case 1: return 'active'
				case 2: return 'type'
				default: return 'workarea'
			}
		},
		
		_getInputsForData: function() {
			return ['details', 'observation']
		},
		
		_getInputObjectsForFirstRow: function(): object[] {
			const { inputs } = this
			return [
				inputs.storage,
				inputs.active,
				inputs.type,
				inputs.workarea
			]
		},

		_getSheetText: function() {
			const { sheet } = this.inputs
			return sheet ? `Num: ${ sheet }` : ''
		},
		
		_indexToColorName: function(index: number) {
			for (const propName in Module3.M30.Defaults.States) {
				const statusOrder = Module3.M30.Defaults.StatesOrder[propName]
				const statusValue = Module3.M30.Defaults.States[propName]
				if (index === statusOrder) return Module3.M30.Defaults.ColorStates[statusValue]
			}
		},

		_resolveButtonStatesDisabled: function(index: number) {
			const disabled = this._getButtonStatesDisabled(index)
			switch (disabled) {
				case 'BY_CONFIRMATION':
				case 'BY_INDEX':
				case 'BY_TRACEABILITY':
					return true
			}
		},

		_resolveLabelsForDataInputs: function(index: number) {
			switch (index) {
				case 0: return 'Detalle*'
				default: return 'Observación'
			}
		},

		_resolveLabelsForFirstRow: function(index: number) {
			switch (index) {
				case 0: return 'Ubicación*'
				case 1: return 'Activo*'
				case 2: return 'Tipo'
				default: return 'Área de Trabajo'
			}
		},

		_visibleOnUpdate: function() {
			return this.states.action === Component.Actions.UPDATE
		},

		getEmptyIdValueObject: function() {
			return { _id: undefined, value: '' }
		},

		getInput: function(input: string): (object | string) {
			return this.inputs[input]
		},

		resetInputs: function() {
			this.inputs = {
				active: this.getEmptyIdValueObject(),
				company: undefined,
				contact: { _id: undefined, email: '', phone: '', value: '' },
				dateProgram: '',
				details: '',
				internal: true,
				observation: '',
				sheet: undefined,
				statusEquipment: false,
				storage: this.getEmptyIdValueObject(),
				technical: this.getEmptyIdValueObject(),
				timeProgram: '',
				title: '',
				type: { value: '' },
				workarea: this.getEmptyIdValueObject()
			}
		},
		
		setAllInputs: function(inputs: object) {
			this.inputs = inputs
		},

		setInput: function(key: string, input: (object | string)) {
			this.inputs[key] = input
		},

		_stateOrTraceabilityControl: function() {
			const { traceability } = this.states

			// Control que determina si la Trazabilidad esta entre los estados 'Creado' y 'Terminado'.
			if (traceability > 0 && traceability < 6) return true

			// Para cualquier otro caso, retornará falso.
			return false
		},

		onPSFCloseClick: function() {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFCloseClick event triggered')
			this.$emit('onPSFCloseClick')
		},
		
		onPSFCopyServiceClick: function() {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFCopyServiceClick event triggered')
			this.$emit('onPSFCopyServiceClick')
		},

		onPSFInputButtonClick: function(index: (number | string)) {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFInputButtonClick event triggered')
			const key = (typeof index === 'string') ? index : this._getFirstRowInputButtonKey(index)
			this.setStates<PopupServiceFormRef['states']>({ inputButtonClicked: key })
			this.$emit('onPSFInputButtonClick', this.states.inputButtonClicked)
		},

		onPSFRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFRefreshButtonClick event triggered')
			this.$emit('onPSFRefreshButtonClick', this.states.inputButtonClicked)
		},

		
		onPSFServiceActionClick: function(action: string) {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFServiceActionClick event triggered')
			this.$emit('onPSFServiceActionClick', action)
		},

		onPSFServiceStateClick: function(index: number) {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFServiceStateClick event triggered')
			const state = this._getButtonServiceState(index)
			if (state) this.$emit('onPSFServiceStateClick', state)
		},

		onPSFSubmitClick: function() {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPSFSubmitClick event triggered')
			this.$emit('onPSFSubmitClick', this.states.action)
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPTClose event triggered')
			this.setStates<PopupServiceFormRef['states']>({ showPopupTable: false })
		},

		onPTSelect: function(currentRow: { item: { _idUser: ObjectId, name: string } }) {
			DevelopmentTools.printWarn('[PopupServiceForm]:onPTSelect event triggered')
			if (currentRow !== undefined) {
				this.setStates<PopupServiceFormRef['states']>({ showPopupTable: false })
				this._popupTable._dataTable.clearCurrentRow()
				this.$emit('onPTSelect', this.states.inputButtonClicked, currentRow)
			}
			else {
				DevelopmentTools.printWarn('[PopupServiceForm]:onPTSelect:"no current row selected"')
			}
		},

		onRowDoubleClick: function(clickedRow: { item: { _idUser: ObjectId, name: string } }) {
			DevelopmentTools.printWarn('[PopupServiceForm]:onRowDoubleClick():Event triggered')
			this.setStates<PopupServiceFormRef['states']>({ showPopupTable: false })
			this._popupTable._dataTable.clearCurrentRow()
			this.$emit('onRowDoubleClick', this.states.inputButtonClicked, clickedRow)
		}
	}
})

// Exports
export default PopupServiceForm
export type PopupServiceFormRef = InstanceType<typeof PopupServiceForm>