// Classes
import { ActionCallbackName, ActionField } from '@/Classes/Records/ActionField'
import { ActionItem } from '@/Classes/Records/ActionItem'
import { ValueTools } from '@/Classes/Static/ValueTools'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module3 {
	export namespace M30 {
		const TempColorStates: { [key: string]: string } = {
			Creado:     'yellow',
			Aceptado:   'blue',
			Ruta:       'teal-darker',
			Trabajando: 'teal-dark',
			Terminado:  'teal',
			Confirmado: 'green',
			Rechazado:  'orange',
			Anulado:    'gray'
		}

		export namespace ActionFields {
			const TempActionFieldStatus = new ActionField('mStatus', 'Estado')
				.addItem('state', 'button')
				.setColor('status', TempColorStates)
				.setPermission('STATUS_BUTTON')
				.setTextFrom('status')
			;

			export const Actives = [
				TempActionFieldStatus
			]
	
			export const History = [
				TempActionFieldStatus,
				new ActionField('checkComment', 'Comentario')
					.on(ActionCallbackName.ITEM_CONDITION, (item: any): (ActionItem | void) => {
						const { comment } = item
						if (!ValueTools.isNullOrUndefined(comment) && comment !== '')
							return new ActionItem('view', 'icon')
								.setIcon(['far', 'list-alt'])
								.setPermission('COMMENT_ICON')
								.setVariant('blue')
							;
					}
				)
			]
	
			export const Services = [
				TempActionFieldStatus,
				new ActionField('actions', 'Acciones')
					.addItem('edit', 'icon')
						.setIcon('pen')
						.setPermission('ACTION_EDIT')
						.setTitle('Editar Servicio')
						.setVariant('green')
					.addItem('history', 'icon')
						.setIcon('history')
						.setPermission('ACTION_HISTORY')
						.setTitle('Ver Historial')
						.setVariant('blue'),
				new ActionField('activeStatus', 'Operativo')
					.on(ActionCallbackName.ITEM_CONDITION, (item: any): (ActionItem | void) => {
						const { statusEquipment } = item
						return new ActionItem('status', 'icon')
							.setIcon(statusEquipment ? 'check-circle' : 'times-circle')
							.setPermission('STATUS_ICON')
							.setText(statusEquipment)
							.setVariant(statusEquipment ? 'green' : 'red')
						;
					}
				)
			]
		}

		export namespace Defaults {
			export const ColorStates: { [key: string]: string } = {
				...TempColorStates
			}
	
			export const States: { [key: string]: string } = {
				CREATED:    'Creado',
				ACCEPTED:   'Aceptado',
				ROUTE:      'Ruta',
				INIT_JOB:   'Trabajando',
				FINISH_JOB: 'Terminado',
				CONFIRMED:  'Confirmado',
				REJECTED:   'Rechazado',
				ANNULED:    'Anulado',
				RESCHEDULE: 'Reprogramar',
				NONE:       'none'
			}
	
			export const StatesOrder: { [key: string]: number } = {
				CREATED:    0,
				ACCEPTED:   1,
				ROUTE:      2,
				INIT_JOB:   3,
				FINISH_JOB: 4,
				CONFIRMED:  5,
				REJECTED:   6,
				ANNULED:    7,
				RESCHEDULE: 8
			}
	
			export enum Traceability {
				CREATED    = 1,
				ACCEPTED   = 2,
				ROUTE      = 3,
				INIT_JOB   = 4,
				FINISH_JOB = 5,
				CONFIRMED  = 6,
				REJECTED   = 7,
				ANNULED    = 8,
				RESCHEDULE = 9
			}
		}

		export namespace JoiSchemas {
			const JoiSchemaLabels = {
				_idStorage:      'Ubicación',
				_idWorkArea:     'Área de Trabajo',
				_idContact:      'Contacto',
				_idEquipment:    'Activo',
				_idTechnical:    'Técnico',
				_idResponsible:  'Responsable',
				comment:         'Comentario',
				dateProgram:     'Fecha Programada',
				details:         'Detalle',
				hourProgram:     'Hora Programada',
				internal:        'Servicio Interno',
				observation:     'Observación',
				sheet:           'Orden de Servicio',
				statusEquipment: 'Estado Equipo',
				title:           'Asunto',
				type:            'Tipo'
			}
	
			export const AddService = Joi.object({
				_idStorage:      Joi.string().hex().required().label(JoiSchemaLabels._idStorage),
				_idWorkArea:     Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
				_idContact:      Joi.string().hex().allow('').label(JoiSchemaLabels._idContact),
				_idEquipment:    Joi.string().hex().required().label(JoiSchemaLabels._idEquipment),
				_idTechnical:    Joi.string().hex().required().label(JoiSchemaLabels._idTechnical),
				_idResponsible:  Joi.string().hex().required().label(JoiSchemaLabels._idResponsible),
				type:            Joi.string().required().label(JoiSchemaLabels.type),
				dateProgram:     Joi.string().allow('').required().label(JoiSchemaLabels.dateProgram),
				hourProgram:     Joi.string().allow('').required().label(JoiSchemaLabels.hourProgram),
				internal:        Joi.boolean().required().label(JoiSchemaLabels.internal),
				title:           Joi.string().required().label(JoiSchemaLabels.title),
				details:         Joi.string().required().label(JoiSchemaLabels.details),
				observation:     Joi.string().allow('').required().label(JoiSchemaLabels.observation),
				statusEquipment: Joi.boolean().required().label(JoiSchemaLabels.statusEquipment),
				sheet:           Joi.number().allow(0).required().label(JoiSchemaLabels.sheet)
			})
			
			export const UpdateService = Joi.object({ 
				_idService:      Joi.string().hex().required(),
				_idStorage:      Joi.string().hex().required().label(JoiSchemaLabels._idStorage),
				_idWorkArea:     Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
				_idContact:      Joi.string().hex().allow('').required().label(JoiSchemaLabels._idContact),
				_idEquipment:    Joi.string().hex().required().label(JoiSchemaLabels._idEquipment),
				_idTechnical:    Joi.string().hex().required().label(JoiSchemaLabels._idTechnical),
				_idResponsible:  Joi.string().hex().required().label(JoiSchemaLabels._idResponsible),
				type:            Joi.string().required().label(JoiSchemaLabels.type),
				dateProgram:     Joi.string().allow('').required().label(JoiSchemaLabels.dateProgram),
				hourProgram:     Joi.string().allow('').required().label(JoiSchemaLabels.hourProgram),
				internal:        Joi.boolean().required().label(JoiSchemaLabels.internal),
				title:           Joi.string().required().label(JoiSchemaLabels.title),
				details:         Joi.string().required().label(JoiSchemaLabels.details),
				observation:     Joi.string().allow('').required().label(JoiSchemaLabels.observation),
				statusEquipment: Joi.boolean().required().label(JoiSchemaLabels.statusEquipment),
				comment:         Joi.string().allow('').label(JoiSchemaLabels.comment),
				stage:           Joi.string(),
				sheet:           Joi.number().required().allow(0).label(JoiSchemaLabels.sheet)
			})
		}

		export namespace Socket {
			export enum Events {
				CREATED = 'ServiceCreated',
				UPDATED = 'ServiceUpdated'
			}
		}
	}
}