// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Module3 }   from '@/Constants/Modules/Module3'

// Store
import Store from '@/Store/Global/Default'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, Module3.M30.ActionFields.Services)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idService', '_idStorage', '_idContact', '_idEquipment', '_idResponsible', '_idTechnical', '_idWorkArea',
		'sheet', 'details', 'history', 'internal', 'observation', 'status', 'statusEquipment', 'traceability', 'dateProgram'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('os', 'OS').isSortable().setAlign(null, 'center')
		.add('registrationDate', 'Fech. Ingreso').isSortable().setAlign(null, 'center').setSortDirection('desc')
		.add('responsible', 'Responsable').isSortable().setAlign(null, 'center')
		.add('equipment', 'Equipo').isSortable().setAlign(null, 'center')
		.add('storage', 'Ubicación').isSortable().setAlign(null, 'center')
		.add('company', 'Cliente').isSortable().setAlign(null, 'center')
		.add('workarea', 'Área').isSortable().setAlign(null, 'center')
		.add('type', 'Tipo').isSortable().setAlign(null, 'center')
		.add('technical', 'Técnico').isSortable().setAlign(null, 'center')
		.add('mDateProgram', 'Programado').setAlign(null, 'center')
		.add('dateEnd', 'Termino').isSortable().setAlign(null, 'center')
		.add('title', 'Asunto').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'os', 'mStatus', 'activeStatus', 'registrationDate', 'responsible', 'equipment', 'storage', 'company', 'workarea',
		'type', 'technical', 'mDateProgram', 'dateEnd', 'title', 'actions'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('os')
		.add('responsible')
		.add('equipment')
		.add('storage')
		.add('company')
		.add('workarea')
		.add('technical')
		.add('mDateProgram')
		.add('dateEnd')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		os: (_: void, item: any) => {
			return item.sheet || 'N/A'
		},

		registrationDate: (value: any) => {
			const date = PrimitiveTools.Dates.parseDateString(value)
			return date ? `${ date.date } ${ date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		responsible: (_: void, item: any) => {
			const { _idResponsible } = item
			const user = Store.getters.getStoredUserById(_idResponsible)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		equipment: (_: void, item: any) => {
			const { _idEquipment } = item
			const equipment = Store.getters.getStoredEquipmentById(_idEquipment)
			return equipment ? `${ equipment.code } ${ equipment.model }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		storage: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			return storage ? `${ storage.code } ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		company: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			if (storage) {
				const company = Store.getters.getStoredCompanyById(storage._idCompany)
				return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		workarea: (_: void, item: any) => {
			const { _idWorkArea } = item
			const workarea = Store.getters.getStoredWorkAreaById(_idWorkArea)
			return workarea ? workarea.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		technical: (_: void, item: any) => {
			const { _idTechnical } = item
			const user = Store.getters.getStoredUserById(_idTechnical)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		mDateProgram: (_: void, item: any) => {
			if (!PrimitiveTools.Dates.isValid(new Date(item.dateProgram))) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const date = PrimitiveTools.Dates.parseDateString(item.dateProgram)
			return date ? `${ date.date } ${ date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseHistoryResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, Module3.M30.ActionFields.History)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_id', '_idResponsible', '_idTechnical', 'comment', 'status'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('date', 'Fecha').isSortable().setAlign(null, 'center')
		.add('activity', 'Actividad')
		.add('responsible', 'Responsable')
		.add('technical', 'Técnico')
	)

	// Aplicar un reordenamiento a los campos.
	bm.rearrangeFields(fields, [
		'date', 'activity', 'responsible', 'technical'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('responsible')
		.add('technical')
	)

	// Función Helper para Obtener el Nombre de un Usuario.
	const _resolveUserName = (_id: string, item: any) => {
		const user = Store.getters.getStoredUserById(item[_id])
		const username = user ? `${user.name} ${user.pLastName}` : null
		return PrimitiveTools.Strings.stringify(username)
	}

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		date: (v: string) => { const _parsed = PrimitiveTools.Dates.parseDateString(v); return _parsed ? `${_parsed.date} ${_parsed.time}` : AppValues.Strings.DEFAULT_EMPTY_STRING },
		responsible: (_: never, item: any) => _resolveUserName('_idResponsible', item),
		technical: (_: never, item: any) => _resolveUserName('_idTechnical', item),
	})

	// Retornar Objeto con las propiedades.
	return { fields, items, actions }
}

export function parseStoppedActivesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, Module3.M30.ActionFields.Actives)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idService', '_idStorage', '_idEquipment', '_idWorkArea', '_idContact', '_idTechnical', '_idResponsible', 'type',
		'registrationDate', 'statusEquipment', 'traceability', 'history', 'details', 'observation', 'status', 'internal', 'dateProgram'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('sheet', 'Num').isSortable().setAlign(null, 'center')
		.add('daysCount', 'Días Detenido').isSortable().setAlign(null, 'center')
		.add('dateStop', 'Fecha Detención').isSortable().setAlign(null, 'center')
		.add('equipment', 'Activo').isSortable().setAlign(null, 'center')
		.add('storage', 'Ubicación').isSortable()
		.add('company', 'Cliente').isSortable()
		.add('title', 'Asunto').isSortable()
		.add('technical', 'Técnico').isSortable()
	)

	// Aplicar un reordenamiento a los campos.
	bm.rearrangeFields(fields, [
		'sheet', 'mStatus', 'daysCount', 'dateStop', 'equipment', 'storage', 'company', 'title', 'technical'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('dateStop')
		.add('equipment')
		.add('storage')
		.add('company')
		.add('technical')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		dateStop: (_: void, item: any) => {
			const { registrationDate } = item
			const date = PrimitiveTools.Dates.parseDateString(registrationDate)
			return date ? `${ date.date } ${ date.time }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		equipment: (_: void, item: any) => {
			const { _idEquipment } = item
			const equipment = Store.getters.getStoredEquipmentById(_idEquipment)
			return equipment ? `${ equipment.code } ${ equipment.model }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		storage: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			return storage ? `${ storage.code } ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		company: (_: void, item: any) => {
			const { _idStorage } = item
			const storage = Store.getters.getStoredStorageById(_idStorage)
			if (storage) {
				const company = Store.getters.getStoredCompanyById(storage._idCompany)
				return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		technical: (_: void, item: any) => {
			const { _idTechnical } = item
			const user = Store.getters.getStoredUserById(_idTechnical)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con las propiedades.
	return { fields, items, actions }
}