// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Vuex }    from '@/Constants/Global/Vuex'
import { Module3 } from '@/Constants/Modules/Module3'

// Dependencies
import Vue from 'vue'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const FilterBar = Vue.extend({
	name: 'FilterBar',

	props: {
		title: String
	},

	data: function() {
		return {
			activeLabel: undefined,
			serviceStatus: [],
			permissions: {
				STOPPED_ACTIVES: null
			}
		}
	},

	created: function() {
		// Almacenar los estados de un Servicio.
		for (const key in Module3.M30.Defaults.States) {
			const status = Module3.M30.Defaults.States[key]
			switch (status) {
				case Module3.M30.Defaults.States.RESCHEDULE: break
				case Module3.M30.Defaults.States.NONE: break
				default: this.serviceStatus.push(status)
			}
		}
	},

	mounted: function() {
		this._initPermissions()
	},

	methods: {
		_applyHoverLabelStyle: function(status: string) {
			const element = (<any> this.$refs)[`filterBar${ status }`][0]
			const style = this._getHoverLabelStyle(status)
			element.style.boxShadow = style.boxShadow
			element.style.color = style.color
		},

		_applyUnhoverLabelStyle: function(status: string) {
			if (status !== this.activeLabel) {
				const element = (<any> this.$refs)[`filterBar${ status }`][0]
				element.style.boxShadow = null
				element.style.color = null
			}
		},

		_getHoverLabelStyle: function(status: string) {
			const h = `var(--hsl-${ this._resolveStatusToColorName(status) }-h)`
			const hoverStyle = {
				boxShadow: `0 0 4px ${ status === Module3.M30.Defaults.States.ANNULED ? '#EEE' : `hsl(${ h }, 100%, 50%)`}`,
				color: status === Module3.M30.Defaults.States.ANNULED ? `#EEE` : `hsl(${ h }, 100%, 50%)`
			}
			return hoverStyle
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._setPermission('STOPPED_ACTIVES', userPermissions.MODULE_30_CHECK_STOPPED_ACTIVES?.privileges.read)
		},

		_resolveLabelStyle: function(status: string): string {
			const h = `var(--hsl-${ this._resolveStatusToColorName(status) }-h)`
			const s = `var(--hsl-${ this._resolveStatusToColorName(status) }-s)`
			const l = `var(--hsl-${ this._resolveStatusToColorName(status) }-l)`
			return (`
				background-color: hsla(${ h }, ${ s }, ${ l }, .75);
				border-color: var(--${ this._resolveStatusToColorName(status) });
			`)
		},

		_resolveStatusToColorName: function(status: string) {
			// Obtener el Color asociado al Status de los Servicios.
			return Module3.M30.Defaults.ColorStates[status]
		},

		_setPermission: function(key: string, value: boolean) {
			(<any> this.permissions)[key] = value
		},

		_updateActiveLabel: function(status: string) {
			// Si son iguales, desmarcar el botón actual.
			if (status === this.activeLabel) {
				this.activeLabel = undefined
				return
			}

			// Guardar el valor del nuevo 'status' clickeado.
			this.activeLabel = status
			for (const key of this.serviceStatus) {
				if (key !== status) this._applyUnhoverLabelStyle(key)
			}
		},

		onFBLabelClick: function(status: string) {
			DevelopmentTools.printWarn('[FilterBar]:onFBLabelClick event triggered')
			this._updateActiveLabel(status)
			this.$emit('onFBLabelClick', status)
		},

		onFBLabelStoppedActivesClick: function() {
			DevelopmentTools.printWarn('[FilterBar]:onFBLabelStoppedActivesClick event triggered')
			this.$emit('onFBLabelStoppedActivesClick')
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions')
	}
})

// Exports
export default FilterBar
export type FilterBarRef = InstanceType<typeof FilterBar>